import React, { useState } from 'react';
import { ShieldCheckIcon, XMarkIcon } from '@heroicons/react/24/solid'; // Iconos representativos de seguridad y cierre


const Footer = () => {
  // Estados para controlar la visibilidad de los modales
  const [isPrivacyOpen, setIsPrivacyOpen] = useState(false);
  const [isTermsOpen, setIsTermsOpen] = useState(false);

  return (
    <footer className="bg-black text-gray-300 py-6">
      <div className="container mx-auto px-4 flex flex-col md:flex-row justify-between items-center">
        {/* Información de Derechos con Icono */}
        <div className="flex items-center">
          <ShieldCheckIcon className="h-6 w-6 text-blue-400 mr-2" />
          <p className="text-sm font-medium">
            © 2024 <span className="text-white">SAFEWEBCHECKER.COM</span>. Todos los derechos reservados.
          </p>
        </div>

        {/* Enlaces de Navegación y Botones de Sesión */}
        <div className="flex space-x-4 mt-4 md:mt-0">
          {/* Botones para abrir los modales */}
          <button 
            onClick={() => setIsTermsOpen(true)} 
            className="text-sm font-medium hover:text-blue-400 transition-colors"
          >
            Términos de Servicio
          </button>
          <button 
            onClick={() => setIsPrivacyOpen(true)} 
            className="text-sm font-medium hover:text-blue-400 transition-colors"
          >
            Política de Privacidad
          </button>
          
        </div>
      </div>

      {/* Modal de Política de Privacidad */}
      {isPrivacyOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 p-4">
          <div className="bg-white rounded-lg shadow-lg max-w-3xl w-full max-h-full overflow-y-auto relative">
            {/* Botón de Cierre */}
            <button
              onClick={() => setIsPrivacyOpen(false)}
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 focus:outline-none"
              aria-label="Cerrar Modal"
            >
              <XMarkIcon className="h-6 w-6" />
            </button>

            {/* Título del Modal */}
            <h2 className="text-2xl font-semibold text-center text-gray-800 mb-6 mt-4">
              Política de Privacidad
            </h2>

            {/* Contenido del Modal */}
            <div className="px-6 pb-6">
              <p className="text-gray-700 mb-4">
                En <span className="font-semibold text-gray-800">SAFEWEBCHECKER.COM</span>, nos comprometemos a proteger tu privacidad. Esta página no almacena ni guarda información personal. Si deseas eliminar tu información de nuestra base de datos, puedes hacerlo haciendo clic en "Borrar Cuenta". Sin embargo, tus comentarios y ratings se guardarán permanentemente sin tu información personal para ayudar a agrandar y mejorar nuestro sistema.
              </p>
              <p className="text-gray-700 mb-4">
                Al utilizar nuestra página, aceptas estos términos y condiciones. Nos esforzamos por mantener un entorno seguro y confiable para todos nuestros usuarios.
              </p>
            </div>
          </div>
        </div>
      )}

      {/* Modal de Términos de Servicio */}
      {isTermsOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 p-4">
          <div className="bg-white rounded-lg shadow-lg max-w-3xl w-full max-h-full overflow-y-auto relative">
            {/* Botón de Cierre */}
            <button
              onClick={() => setIsTermsOpen(false)}
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 focus:outline-none"
              aria-label="Cerrar Modal"
            >
              <XMarkIcon className="h-6 w-6" />
            </button>

            {/* Título del Modal */}
            <h2 className="text-2xl font-semibold text-center text-gray-800 mb-6 mt-4">
              Términos de Servicio
            </h2>

            {/* Contenido del Modal */}
            <div className="px-6 pb-6">
              <p className="text-gray-700 mb-4">
                Bienvenido a <span className="font-semibold text-gray-800">SAFEWEBCHECKER.COM</span>. Al utilizar nuestra plataforma, aceptas cumplir con los siguientes términos y condiciones:
              </p>
              <ul className="list-disc list-inside text-gray-700 mb-4">
                <li>No almacenar ni compartir información personal.</li>
                <li>Utilizar la plataforma de manera responsable y respetuosa.</li>
                <li>Permitir que tus comentarios y ratings se guarden de manera anónima para mejorar nuestros servicios.</li>
                <li>Reconocer que tienes la opción de eliminar tu cuenta, lo cual eliminará tu información personal pero no tus contribuciones anónimas.</li>
              </ul>
              <p className="text-gray-700">
                Nos reservamos el derecho de actualizar estos términos en cualquier momento. Te recomendamos revisar esta página periódicamente para estar al tanto de cualquier cambio.
              </p>
            </div>
          </div>
        </div>
      )}
    </footer>
  );
};

export default Footer;