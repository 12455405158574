import React from "react";
import GoogleLoginButton from "../components/common/GoogleButton";

const TestingComponent = () => {
  console.log('Init testing page');
  return (
    <GoogleLoginButton/>
  );
};

export default TestingComponent;
