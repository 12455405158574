// src/components/GoogleLoginButton.jsx
import React  from 'react';
import { GoogleLogin } from '@react-oauth/google';
import GoogleService from '../../services/google';
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

const GoogleLoginButton = () => {
  const navigate = useNavigate();
  const handleSuccess = async (credentialResponse) => {
    try {
      console.log('Estamos en GoogleLoginButton');
      const { credential } = credentialResponse;
      console.log('Credential:', credential);
      const response = await GoogleService.google_callback(credential);
      console.log("Respuesta de handleSuccess:", response);
      const { token } = response.data;
      // Guarda el token en cookies
      Cookies.set("token", token, { expires: 7 });
      console.log("Token guardado en cookies:", token);

      // Redirige al usuario a la página principal
      navigate("/", { replace: true });
      window.location.reload();
      // Opcional: Redirigir al usuario después del login
      // window.location.href = '/dashboard';
    } catch (error) {
      console.error("Error en handleSuccess:", error);
      // Opcional: Manejar el error según tus necesidades
    }
  };

  const handleError = () => {
    console.log('Login Failed');
    // Opcional: Manejar el error según tus necesidades
  };

  return (
    <div className="flex justify-center">
      <GoogleLogin
        onSuccess={handleSuccess}
        onError={handleError}
        render={(renderProps) => (
          <button
            onClick={renderProps.onClick}
            disabled={renderProps.disabled}
            className="flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition duration-150 ease-in-out"
          >
            <span className="text-gray-700 font-medium">Iniciar sesión con Google</span>
          </button>
        )}
      />
    </div>
  );
};

export default GoogleLoginButton;
