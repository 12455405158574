import React, { useEffect, useState } from "react";
import { ShieldCheckIcon } from "@heroicons/react/24/solid";

const ShowLoading = ({
  showLoading,
  message,
  timer = process.env.NODE_ENV === 'production' ? 60000 : 0,
  onClose
}) => {
  const [showError, setShowError] = useState(false);

  // Efecto para iniciar el temporizador y mostrar el error tras 'timer' ms
  useEffect(() => {
    if (showLoading) {
      const timerId = setTimeout(() => {
        setShowError(true);
      }, timer);
      return () => clearTimeout(timerId);
    }
  }, [showLoading, timer]);

  // Efecto para deshabilitar el scroll cuando el modal está visible
  useEffect(() => {
    if (showLoading || showError) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    // Limpieza por si el componente se desmonta
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [showLoading, showError]);

  const handleClose = () => {
    setShowError(false);
    if (onClose) {
      onClose(); // Llama a la función onClose para manejar el cierre externamente
    }
  };

  // Si no está cargando ni en error, no mostramos nada
  if (!showLoading && !showError) return null;

  return (
    <>
      {/* Fondo con desenfoque */}
      <div className="fixed inset-0 flex items-center justify-center z-50 overlay-blur">
        {/* Contenedor con fondo negro sólido */}
        <div className="glass-container flex flex-col items-center justify-center p-10 rounded-2xl shadow-xl relative max-w-lg">
          {showError ? (
            // Mensaje de Error
            <div className="flex flex-col items-center">
              <p className="text-red-500 text-lg font-semibold mb-4">
                El análisis ha demorado más de lo esperado. Por favor, inténtalo nuevamente.
              </p>
              <button
                onClick={handleClose}
                className="bg-red-600 hover:bg-red-700 text-white py-3 px-6 rounded-lg shadow-md transition duration-300"
              >
                Aceptar
              </button>
            </div>
          ) : (
            // Loader y contenido de carga
            <>
              <div className="loader-wobble relative flex items-center justify-center mb-8">
                <ShieldCheckIcon className="h-16 w-16 text-accent animate-pulse absolute" />
              </div>

              <div className="w-full bg-gray-800 rounded-full h-3 mt-6 mb-6">
                <div
                  className="bg-green-500 h-3 rounded-full loading-bar"
                  style={{ animationDuration: `${timer}ms` }}
                ></div>
              </div>

              {message && (
                <p className="text-white text-xl font-semibold mt-6 animate-pulse text-center">
                  {message}
                </p>
              )}
            </>
          )}
        </div>
      </div>

      {/* Estilos personalizados */}
      <style>
        {`
          /* Barra de carga animada */
          .loading-bar {
            animation: loadingBarAnimation linear forwards;
            width: 0%;
          }
          @keyframes loadingBarAnimation {
            to {
              width: 100%;
            }
          }

          /* Fondo desenfocado (overlay) */
          .overlay-blur {
            background: rgba(0, 0, 0, 0.5); /* Más oscuro para disimular el manchado */
            backdrop-filter: blur(2px);     /* Blur leve (2px) */
            -webkit-backdrop-filter: blur(2px);
            transition: backdrop-filter 0.2s ease, background 0.2s ease;
          }

          /* Contenedor negro sólido */
          .glass-container {
            background: #000 !important;     /* Fondo negro */
            backdrop-filter: none !important;
            -webkit-backdrop-filter: none !important;
            border: 1px solid rgba(255, 255, 255, 0.2);
            max-width: 90%;
            min-width: 320px;
            padding: 2.5rem;
            text-align: center;
          }

          /* Loader principal */
          .loader-wobble {
            position: relative;
            width: 9em;
            height: 9em;
          }

          /* Loader círculos */
          .loader-wobble::before,
          .loader-wobble::after {
            content: '';
            position: absolute;
            width: 9em;
            height: 9em;
            top: 0;
            left: 0;
            border-radius: 50%;
          }

          /* Anillo interior */
          .loader-wobble::before {
            box-shadow: inset 0 0 0 0.2em rgba(0, 224, 199, 0.6); /* Verde Neón */
            animation: loadingInner 1.2s infinite ease-in-out alternate;
          }

          /* Anillo exterior */
          .loader-wobble::after {
            border: 0.4em dashed #006270; /* Primario Oscuro */
            border-top: 0.4em solid #009394; /* Primario Claro */
            border-radius: 50%;
            transform-origin: center center;
            animation: loadingOuter 1.5s infinite linear;
          }

          /* Animaciones anillos */
          @keyframes loadingOuter {
            to {
              transform: rotate(360deg);
            }
          }
          @keyframes loadingInner {
            to {
              transform: scale(0);
            }
          }
        `}
      </style>
    </>
  );
};

export default ShowLoading;
