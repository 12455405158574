import React, { useEffect, useState, useContext } from "react";
import HeroSection from "../components/common/HeroSection";
import ResultAnalysisComponent from "../components/common/ResultAnalysisComponent";
import CommentsSection from "../components/common/UserCommentsComponent";
import SecurityPercentageComponent from "../components/common/SecurityPercentageComponent";
import SectionHowItWorks from "../components/common/HowItWorks";
import { ValueContext } from "../components/context/ValueContext";
import MobileOnlyPage from "./MobilePage"; // Importar la página móvil
import useGoogleGetToken from "../components/hooks/googleGetToken"; // Importar el custom hook
import Counter from "../components/hooks/counterVisits";

const HomePage = () => {
  const [isMobile, setIsMobile] = useState(false);
  const { value } = useContext(ValueContext);
  useGoogleGetToken();
  
  // Hook para verificar la resolución de la pantalla
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); // Consideramos tablet o inferior si es menor a 768px
    };
    handleResize(); // Verificar el tamaño inicial
    window.addEventListener("resize", handleResize); // Agregar listener para el cambio de tamaño

    // Limpieza del listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Si la pantalla es menor a 768px de ancho, mostramos MobileOnlyPage
  if (isMobile) {
    return <MobileOnlyPage />;
  }

  // Renderización de HomePage en resoluciones más grandes
  return (
    <div className="relative bg-gradient-to-r from-gray-900 via-black to-gray-900 text-gray-200 overflow-hidden">
      <Counter />
      {/* Sección de la barra de búsqueda */}
      <HeroSection />

      {/* Contenido principal */}
      <div className="container mx-auto px-4 lg:px-8 py-8">
        {value.trim() !== "" ? (
          <>
            {/* Mostrar estos componentes cuando 'value' tiene algo */}
            <div className="my-12">
              <ResultAnalysisComponent
                action={true}
                response={
                  "Con la potencia de la inteligencia artificial, SafeWebChecker puede detectar patrones sospechosos, prevenir estafas y brindarte análisis detallados para mantenerte protegido en línea. Confía en nuestra tecnología de vanguardia para una seguridad integral."
                }
              />
            </div>

            <div className="my-12">
              <SecurityPercentageComponent show={true} percent={60} />
            </div>

            <div className="my-12">
              <CommentsSection />
            </div>
          </>
        ) : (
          <>
            {/* Mostrar estos componentes cuando 'value' no tiene nada */}
            <div>
              <SectionHowItWorks />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default HomePage;