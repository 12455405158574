// src/Api/VisitorService.js

/* Sección para tener un registro de los usuarios que visitan la página */

import axiosInstance from "../utils/AxiosConfig";

const VisitorService = {
  actualizar_visitas: () => {
    try {
      const response = axiosInstance.post("/actualizar_visitas");
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  obtener_visitas: () => {
    try {
      const response = axiosInstance.get("/obtener_visitas");
      return response;
    } catch (error) {
      console.log(error);
    }
  }
}

export default VisitorService;