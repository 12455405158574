import React, { useContext, useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { ValueContext } from '../context/ValueContext';
import WebsiteService from "../../services/website";
import ShowLoading from '../common/LoadingComponent';
import { formatURL } from "../../utils/functions/Format";
import { detectInputType } from "../../utils/functions/InputType";

// El componente muestra un campo de texto con la respuesta si action es true
const ResultAnalysisComponent = ({ action }) => {
  const { value } = useContext(ValueContext);
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [formatValue, setFormatValue] = useState("");
  
  // Create a reference for the result section
  const resultRef = useRef(null);

  // Use useEffect to set formatValue to avoid infinite render loop
  useEffect(() => {
    if (detectInputType(value) === "Website") {
      setFormatValue(formatURL(value));
    } else {
      setFormatValue(value);
    }
  }, [value]);

  useEffect(() => {
    if (action && value.trim() !== '') {
      const fetchData = async () => {
        setLoading(true);
        try {
          const result = await WebsiteService.checkUrlOpenAi(value);
          setResponse(result.data.result);
          console.log('response', result);
          
          // After setting the response, scroll to the result section
          if (resultRef.current) {
            resultRef.current.scrollIntoView({ behavior: 'smooth' });
          }

        } catch (error) {
          console.error('Error al obtener la respuesta:', error);
          setResponse("Error al obtener datos...");
        } finally {
          setLoading(false);
        }
      };
      fetchData();
    }
  }, [action, value]);

  // Function to format the response into a more readable format for terminal
  const formatResponse = (text) => {
    if (!text || text.trim() === '') return "Sin datos disponibles...";
  
    // Dividir el texto por los números que tienen un punto para detectar la parte que viene después del encabezado inicial
    const [header, ...rest] = text.split(/(\d+\.\s)/).filter((str) => str.trim() !== "");
  
    // Reconstruir el texto: dejar el encabezado con un salto de línea, pero juntar todo lo demás sin más saltos
    return `${header.trim()}\n${rest.join("").trim()}`;
  };

  if (!action) {
    return null; // Si action es false, no renderiza nada
  }

  return (
    <div>
      {/* Resultado de IA */}
      <div
        ref={resultRef} // Attach the reference here
        className="p-4 md:p-6 text-sm md:text-base whitespace-pre-wrap break-words leading-relaxed text-left"
      >
        <h2 className="font-semibold text-xl md:text-3xl bg-gradient-to-r from-green-400 via-teal-400 to-blue-500 bg-clip-text text-transparent mb-4">
          Resultado del Análisis
        </h2>
        {/* Estilo de terminal */}
        <div className="mt-2 p-5 md:p-6 bg-black rounded-md border border-gray-700 font-mono text-xs md:text-sm shadow-lg relative">
          {/* Fondo de terminal y borde */}
          <div className="absolute inset-0 opacity-10 pointer-events-none bg-black via-teal-300 to-blue-400" />

          {loading ? (
            <ShowLoading 
              showLoading={true} 
              message={`Estamos haciendo un análisis de ${formatValue}, espera un momento...`} 
              onClose={() => setLoading(false)} 
            />
          ) : (
            <pre className="whitespace-pre-wrap" style={{
              backgroundImage: "linear-gradient(to right, #00ff00, #66ff66, #00e5e5, #0057ff)", // Degradado verde a azul
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent"
            }}>
              {formatResponse(response)}
            </pre>
          )}
        </div>
      </div>
    </div>
  );
};

// Definir tipos de props para mayor robustez
ResultAnalysisComponent.propTypes = {
  action: PropTypes.bool.isRequired,
};

export default ResultAnalysisComponent;