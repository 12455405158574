import { useEffect } from "react";
import VisitorService from "../../services/visitor";

function Counter() {
  useEffect(() => {
    actualizar_visitas();
  }, [])
  const actualizar_visitas = async () => {
    try {
      const response1 = await VisitorService.actualizar_visitas();
      console.log("Actualizando visitas", response1.data.number);
    } catch (error) {
      console.log('Error al intentar actualizar visitas');
    }
  }
}

export default Counter;