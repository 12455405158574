// src/components/ButtonSessions/ButtonSessions.jsx

import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { AuthContext } from "../context/AuthContext";
import userService from "../../services/user";

const ButtonSessions = ({ openModal }) => {
  // Obtenemos el usuario autenticado desde el contexto
  const { user } = useContext(AuthContext);

  // useNavigate nos retorna la función para navegar
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      const response = await userService.logout();
      console.log("Cerrando sesión", response);
  
      if (response.status === 200) {
        console.log("Sesión cerrada correctamente");
  
        // Elimina el token antes de recargar la página
        Cookies.remove("token");
  
        // Navega a la ruta deseada
        navigate("/");
  
        // Fuerza la recarga de la página
        window.location.reload();
      }
    } catch (error) {
      console.error("Error al cerrar sesión:", error);
    }
  };

  return (
    <div className="ml-3 flex items-center space-x-4">
      {user ? (
        <button
          onClick={handleLogout}
          className="
            px-3 py-1 
            text-sm font-medium 
            text-white 
            bg-red-700 
            rounded-md
            hover:bg-red-800
            focus:outline-none 
            focus:ring-2 
            focus:ring-red-500 
            focus:ring-offset-2 
            shadow-sm
            transition 
            duration-200
          "
        >
          Cerrar Sesión
        </button>
      ) : (
        <button
          onClick={openModal}
          className="
            px-5 py-2 
            text-sm font-medium 
            text-white 
            bg-blue-800
            rounded-md
            hover:bg-blue-900
            focus:outline-none 
            focus:ring-2 
            focus:ring-blue-600 
            focus:ring-offset-2 
            shadow-lg
            transition 
            duration-200
          "
        >
          Iniciar Sesión
        </button>
      )}
    </div>
  );
};

export default ButtonSessions;