import React, { useState, useContext } from 'react';
import { AuthContext } from '../context/AuthContext'; // Importación correcta
import { useNavigate } from 'react-router-dom';
import GoogleLoginButton from './GoogleButton';

/**
 * Modal para autenticación (login y registro).
 */
export default function AuthModal({ isOpen, setIsOpen }) {
  const [isRegistering, setIsRegistering] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [message, setMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);

  const { login, register } = useContext(AuthContext);
  const navigate = useNavigate();

  /**
   * Resetea los campos del formulario.
   */
  const resetFields = () => {
    setEmail('');
    setPassword('');
    setPasswordConfirmation('');
    setMessage('');
    setIsSuccess(false);
  };


  const handleLogin = async (e) => {
    e.preventDefault();
    setMessage('');
    try {
      await login({ email, password });
      setMessage('Sesión iniciada con éxito, redirigiendo...');
      setIsSuccess(true);
      setTimeout(() => {
        resetFields();
        setIsOpen(false);
        navigate('/'); // Redirige a la página de inicio o donde desees
      }, 2000);
    } catch (error) {
      console.error('Error al iniciar sesión:', error);
      setIsSuccess(false);
      setMessage(
        error.response?.data?.errors?.[0] ||
          'Error al iniciar sesión. Por favor verifica tus credenciales.'
      );
    }
  };

  /**
   * Maneja el registro de un nuevo usuario.
   * @param {Event} e - Evento de envío del formulario.
   */
  const handleRegister = async (e) => {
    e.preventDefault();
    setMessage('');
    if (password !== passwordConfirmation) {
      setMessage('Las contraseñas no coinciden.');
      setIsSuccess(false);
      return;
    }
    try {
      await register({ email, password, password_confirmation: passwordConfirmation });
      setMessage('Registro exitoso, redirigiendo...');
      setIsSuccess(true);
      setTimeout(() => {
        resetFields();
        setIsOpen(false);
        navigate('/'); // Redirige a la página de inicio o donde desees
      }, 2000);
    } catch (error) {
      console.error('Error al registrar:', error);
      setIsSuccess(false);
      setMessage(
        error.response?.data?.errors?.[0] ||
          error.response?.data?.[0] ||
          'Error al registrar.'
      );
    }
  };

  /**
   * Cierra el modal y resetea los campos.
   */
  const handleClose = () => {
    setIsOpen(false);
    resetFields();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md relative">
        {/* Botón para cerrar el modal */}
        <button
          onClick={handleClose}
          className="absolute top-3 right-3 text-gray-500 hover:text-gray-800"
          aria-label="Cerrar modal"
        >
          &times;
        </button>

        {/* Título */}
        <h2 className="text-2xl font-semibold mb-4 text-center text-black">
          {isRegistering ? 'Regístrate' : 'Inicia Sesión'}
        </h2>

        {/* Mensaje de recomendación */}
        <p className="text-center text-sm text-gray-700 mb-4">
          Para una mejor experiencia, te recomendamos estar autenticado y
          obtener créditos. Revisa nuestros{' '}
          <a href="/payment" className="text-blue-500 hover:underline">
            planes
          </a>
          .
        </p>

        {/* Mensaje de éxito o error */}
        {message && (
          <p
            className={`text-center mb-4 ${
              isSuccess ? 'text-green-500' : 'text-red-500'
            }`}
          >
            {message}
          </p>
        )}

        {/* Formulario de Login / Registro */}
        <div className="mb-4">
          <form
            className="flex flex-col space-y-4"
            onSubmit={isRegistering ? handleRegister : handleLogin}
          >
            <input
              type="email"
              placeholder="Correo Electrónico"
              className="p-2 border border-gray-300 rounded text-black"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              aria-label="Correo Electrónico"
            />
            <input
              type="password"
              placeholder="Contraseña"
              className="p-2 border border-gray-300 rounded text-black"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              aria-label="Contraseña"
            />
            {isRegistering && (
              <input
                type="password"
                placeholder="Confirmar Contraseña"
                className="p-2 border border-gray-300 rounded text-black"
                value={passwordConfirmation}
                onChange={(e) => setPasswordConfirmation(e.target.value)}
                required
                aria-label="Confirmar Contraseña"
              />
            )}
            <button
              type="submit"
              className="bg-blue-500 text-white py-2 rounded hover:bg-blue-600"
            >
              {isRegistering ? 'Registrarse' : 'Iniciar Sesión'}
            </button>
          </form>
        </div>

        {/* Alternar entre Registro y Login */}
        <p className="text-center text-sm">
          {isRegistering ? (
            <>
              <span className="text-black">¿Ya tienes una cuenta?</span>{' '}
              <button
                className="text-blue-500 hover:underline"
                onClick={() => setIsRegistering(false)}
              >
                Inicia Sesión
              </button>
            </>
          ) : (
            <>
              <span className="text-black">¿No tienes una cuenta?</span>{' '}
              <button
                className="text-blue-500 hover:underline"
                onClick={() => setIsRegistering(true)}
              >
                Regístrate
              </button>
            </>
          )}
        </p>

        {/* Enlace para recuperar contraseña */}
        <div className="mt-2 text-center">
          <button
            type="button"
            className="text-sm text-blue-500 hover:underline"
            onClick={() => console.log('¿Olvidaste tu contraseña?')}
          >
            ¿Olvidaste tu contraseña?
          </button>
        </div>

        {/* Inicio de sesión con Google */}
        <div className="mt-4 text-center w-full">
          <GoogleLoginButton/>
        </div>
      </div>
    </div>
  );
}
