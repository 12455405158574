import axiosInstance from '../utils/AxiosConfig';

const UserService = {
  getCurrentUser: () => axiosInstance.get('/current_user'),
  login: (credentials) => axiosInstance.post('/login', { user: credentials }),
  register: (data) => axiosInstance.post('/register', { user: data }),
  logout: () => axiosInstance.delete('/logout'),
  delete: () => axiosInstance.delete('/delete_account')
};

export default UserService;