import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { createRating, getRating } from "../../services/rating";
import { ValueContext } from "../context/ValueContext";

import image_1 from "../../images/1.png";
import image_2 from "../../images/2.png";
import image_3 from "../../images/3.png";
import image_4 from "../../images/4.png";
import image_5 from "../../images/5.png";

const UnifiedComponent = ({ percent, show }) => {
  const { value } = useContext(ValueContext);
  const [score, setScore] = useState(0);
  const [pervalue, setPercent] = useState(percent);

  // Datos de experiencias de usuarios con imágenes y etiquetas
  const feelingsData = [
    { id: 1, url: image_5, label: "Muy Mal" },
    { id: 2, url: image_4, label: "Mal" },
    { id: 3, url: image_3, label: "Más o menos" },
    { id: 4, url: image_2, label: "Bien" },
    { id: 5, url: image_1, label: "Excelente" },
  ];

  useEffect(() => {
    setPercent(0);
    const fetchRating = async () => {
      if (!value) return;

      try {
        const response = await getRating(value);
        console.log(`El valor inicial es: ${response.score}`);
        setScore(response.score);
        setPercent(response.percentage);
      } catch (error) {
        console.error("Error fetching rating:", error);
      }
    };

    fetchRating();
  }, [value]);

  if (!show) return null;

  const getDynamicColor = (pervalue) => {
    const r = pervalue < 50 ? 255 : Math.round(255 - (pervalue - 50) * 5.1);
    const g = pervalue > 50 ? 255 : Math.round(pervalue * 5.1);
    return `rgb(${r}, ${g}, 0)`;
  };

  const setRatingValue = async (id, value) => {
    console.log("Click en emoción", id);
    try {
      const response = await createRating(value, id);
      console.log(response.rating.score);
      const newScore = response.rating.score;
      const percent_value = response.percentage;
      setScore(newScore);
      setPercent(percent_value);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  // Obtener el color dinámico basado en el porcentaje
  const dynamicColor = getDynamicColor(pervalue);

  // Cálculo del stroke-dasharray ajustado para valores bajos
  const radius = 130;
  const circumference = Math.PI * radius;
  const strokeDasharray = Math.max((pervalue / 100) * circumference, 5);
  const strokeGap = circumference - strokeDasharray;

  return (
    <div className="w-full  p-8 ">
      {/* Sección Superior: Título y Descripción */}
      <div className="mb-8 text-center">
        <h1 className="text-2xl md:text-3xl font-bold text-gray-200 mb-4">
          El estado de Seguridad es de {pervalue}%
        </h1>
        <p className="text-sm md:text-base text-gray-400">
          Este indicador muestra el porcentaje de seguridad del sitio web. Un 0%
          indica un alto riesgo, mientras que un 100% señala una máxima
          confiabilidad.
        </p>
      </div>

      {/* Sección Principal: Arco y Botones */}
      <div className="flex flex-col lg:flex-row justify-between items-stretch mb-8 space-y-8 lg:space-y-0 lg:space-x-8">
        {/* Arco de Seguridad */}
        <div
          className="w-full lg:w-1/3 bg-background p-6 flex flex-col items-center justify-center shadow-inner"
          style={{ height: "300px" }}
        >
          <div className="relative w-full flex items-center justify-center">
            <svg viewBox="0 0 300 150" className="w-48 h-48 md:w-64 md:h-64">
              <g
                style={{
                  transformOrigin: "center center",
                  transform: "scale(1, -1)",
                }}
              >
                {/* Arco de Fondo */}
                <path
                  d="M 20 0 A 130 130 0 0 0 280 0"
                  fill="none"
                  stroke="#1F2937" // Gris oscuro
                  strokeWidth="14"
                ></path>
                {/* Arco de Progreso */}
                <path
                  d="M 20 0 A 130 130 0 0 0 280 0"
                  fill="none"
                  stroke={dynamicColor}
                  strokeWidth="14"
                  strokeDasharray={`${strokeDasharray} ${strokeGap}`}
                  strokeLinecap="round"
                  style={{
                    transition:
                      "stroke-dasharray 0.5s ease-in-out, stroke 0.5s ease-in-out",
                  }}
                ></path>
              </g>
            </svg>
            {/* Número de Porcentaje dentro del Arco */}
            <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 text-center">
              <span className="text-4xl md:text-5xl font-bold text-gray-200">
                {pervalue}%
              </span>
              <span className="block text-xs md:text-sm text-gray-400">
                Porcentaje de Seguridad
              </span>
            </div>
          </div>
          {/* Textos cerca de las patas del arco */}
          <div className="flex justify-between w-full mt-4 text-xs text-gray-400 px-4">
            <span>Riesgo Alto</span>
            <span>Máxima Seguridad</span>
          </div>
        </div>

        {/* Botones de Experiencia del Usuario */}
        <div
          className="w-full lg:w-2/3 bg-background p-4 lg:p-6 shadow-inner flex flex-col"
          style={{ height: "300px" }}
        >
          <h2 className="text-lg sm:text-xl md:text-2xl font-semibold text-gray-200 mb-3 sm:mb-4 md:mb-6">
            ¿Cómo calificarías tu experiencia con este sitio?
          </h2>

          <div className="flex-grow grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-2 sm:gap-4">
            {feelingsData.map((feeling) => (
              <button
                onClick={() => setRatingValue(feeling.id, value)}
                key={feeling.id}
                className={`bg-background-dark rounded-lg shadow-md flex flex-col items-center justify-center p-2 sm:p-3 md:p-4 hover:bg-primary transition-all duration-300 ${
                  score === feeling.id
                    ? "bg-primary text-white"
                    : "text-gray-300"
                }`}
                title={feeling.label}
                aria-label={`Experiencia ${feeling.label}`}
              >
                <div className="w-10 h-10 sm:w-12 sm:h-12 md:w-16 md:h-16 shadow-inner flex items-center justify-center mb-1 sm:mb-2">
                  <img
                    src={feeling.url}
                    alt={feeling.label}
                    className="w-full h-full object-contain"
                  />
                </div>
                <span className="text-center text-xs sm:text-sm md:text-base">
                  {feeling.label}
                </span>
              </button>
            ))}
          </div>

          {/* Información adicional */}
          <div className="mt-3 sm:mt-4 md:mt-6 text-gray-400 text-xs sm:text-sm md:text-base">
            Selecciona una opción para expresar tu experiencia con la seguridad
            de este sitio web.
          </div>
        </div>
      </div>
    </div>
  );
};

UnifiedComponent.propTypes = {
  percent: PropTypes.number.isRequired,
  show: PropTypes.bool.isRequired,
};

export default UnifiedComponent;
