import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

// Custom Hook para manejar el token de Google
const useGoogleGetToken = () => {
  const navigate = useNavigate();

  useEffect(() => {
    console.log("Init GoogleGetToken...");

    // Captura la URL actual
    const currentUrl = window.location.href;
    console.log("Current URL:", currentUrl);

    // Captura los parámetros de búsqueda
    const params = new URLSearchParams(window.location.search);
    const token = params.get("token");
    console.log("Token encontrado en search params:", token);

    // Fallback: verificar si el token está en el hash (opcional)
    if (!token && window.location.hash) {
      const hashParams = new URLSearchParams(window.location.hash.slice(1));
      const hashToken = hashParams.get("token");
      console.log("Token encontrado en hash params:", hashToken);

      if (hashToken) {
        Cookies.set("token", hashToken, { expires: 7 });
        console.log("Token guardado en cookies desde hash:", hashToken);
        navigate("/", { replace: true });
        return;
      }
    }

    // Si el token está presente en search params
    if (token) {
      // Guarda el token en cookies
      Cookies.set("token", token, { expires: 7 });
      console.log("Token guardado en cookies:", token);

      // Redirige al usuario a la página principal
      navigate("/", { replace: true });
    } else {
      console.log("No se encontró token en la URL.");
    }
  }, [navigate]);
};

export default useGoogleGetToken;
